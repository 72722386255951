/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.w-text {
  color: var(--white);
}

.b-text{
  color: var(--black);
}

.button-red {
  --background: var(--btn-red);
  --border-color: var(--btn-red-border);
}

.button-secondary {
  --background: var(--btn-grey);
  --color: var(--btn-grey-color)
}

ion-item {
  --ripple-color: transparent;
}

ion-spinner{
  color: var(--btn-red) !important;
}

ion-back-button{
  --color:var(--white);
}

.loading-class {
  --min-width: 75vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 17px;
  font-weight: bold;
  // border-radius: 20px !important;
  ion-spinner {
    color: var(--btn-red);
  }
  .loading-wrapper {
    border-radius: 20px !important;
  }
}

.toast-class-error {
  --color: var(--white);
  --background: var(--error);
  &::part(header){
    font-size: 20px;
    font-weight: bold;
  }
  &::part(message){
    font-size: 16px;
  }
  
}

.toast-class-success {
  --color: var(--white);
  --background: var(--success);
  &::part(header){
    font-size: 20px;
    font-weight: bold;
  }
  &::part(message){
    font-size: 16px;
  }
}

.toast-class-warn {
  --color: var(--white);
  --background: var(--warn);
  &::part(header){
    font-size: 20px;
    font-weight: bold;
  }
  &::part(message){
    font-size: 16px;
  }
}

.action-sheet-custom{
  .action-sheet-title{
    font-size: 18px;
    font-weight: bold;
    color: var(--black);
  }
  .action-sheet-cancel{
    font-weight: bold;
    font-size: 16px;

  }
}

.alert-delete{
  .secondary-cancel{
    color: var(--btn-red);
  }
  .secondary-acept{
    color:var(--btn-red);
  }
}

.flex {
  display: flex;
}

ion-card{
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 1px -2px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}


.icon-category {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--border-card);
  border-radius: 50%;
  margin-right: 15%;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  ion-icon {
    color: var(--white);
    font-size: 24px;
  }
}